.radar {
  $size: 40px;
  background: url("./radar.png") no-repeat 50% 50%;
  width: $size;
  height: $size;
  position: relative;
  background-size: $size $size;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -$size / 2;
  margin-top: -$size / 2;

  :hover{
    background: none;

  }
  .pointer {
    position: absolute;
    z-index: 2;
    left: 10.5820106%;
    right: 10.5820106%;
    top: 10.5820106%;
    bottom: 50%;
    will-change: transform;
    transform-origin: 50% 100%;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background-image: linear-gradient(
      135deg,
      rgba(5, 162, 185, 0.8) 0%,
      rgba(0, 0, 0, 0.02) 70%,
      rgba(0, 0, 0, 0) 100%
    );
    clip-path: polygon(
      100% 0,
      100% 10%,
      //控制扇形角度 100% => 135deg
      50% 100%,
      0 100%,
      0 0
    );

    animation: rotate360 3s infinite linear;
  }

  .pointer:after {
    content: "";
    position: absolute;
    width: 50%;
    bottom: -1px;
    border-top: 2px solid rgba(0, 231, 244, 0.8);
    box-shadow: 0 0 3px rgba(0, 231, 244, 0.6);
    border-radius: 9px;
  }

  .shadow {
    position: absolute;
    left: 11%;
    top: 11%;
    right: 11%;
    bottom: 11%;
    margin: auto;
    border-radius: 9999px;
    box-shadow: 0 0 66px 6px #a51414;
    animation: shadow 1s infinite ease;
  }

  @keyframes rotate360 {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  @keyframes shadow {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}