@import "node_modules/bootstrap/scss/bootstrap";
@import "./style/vars";

.btn-main {
  background: -webkit-linear-gradient(95deg, $main-gradient);
  background: linear-gradient(95deg, $main-gradient);
  color: #fff;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.4rem;
  font-weight: bold;
}

/* Loader */

.loader-full-screen, .loader-full-section {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.loader-full-section {
  position: absolute;
}

.loader-full-screen .cover, .loader-full-section .cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
}

.loader-fixed {
  display: inline-block;
  background: white;
  border-radius: 50%;
  padding: 9px 10px 5px 10px;
  top: 50px;
  position: fixed;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  z-index: 99999999;
}