.loginForm {
  justify-content: center;
  align-items: flex-start;
  &-container {
    max-width: 40%;
  }

  &-logo {
    max-width: 100%;
  }

  &-alert {
    max-width: 45%;
    padding: 1.5rem;
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    background-color: rgb(212, 212, 212, 0.3);
  }
  .leftAlertBorder {
    border-left: 0.4rem solid rgba(9, 9, 121);
  }

  .rightAlertBorder {
    border-right: 0.4rem solid rgba(44, 157, 53);
  }
}

@media (max-width: 1300px) {
  .loginForm {
    &-container {
      max-width: 80%;
    }
  }
}

@media (max-width: 900px) {
  .loginForm {
    &-container {
      max-width: 100%;
    }
  }
}
